import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';

import { GoogleAuthProvider, connectAuthEmulator, getAuth, signInWithPopup } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const storage = getStorage(app);
export const db = getFirestore(app);
const analytics = getAnalytics(app);

// Uncomment to use emulator

// connectFirestoreEmulator(db, 'localhost', 8080);
// async function setupEmulators(auth: any) {
//   const authUrl = 'http://localhost:9099';
//   await fetch(authUrl);
//   connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
//   // why? to make sure that emulator are loaded
// }
// setupEmulators(auth);

const provider = new GoogleAuthProvider();

export const signInWithGoogle = async () => {
  return await signInWithPopup(auth, provider);
};

// TODO: Push notifications WIP

// const messaging = (async () => {
//   try {
//     const isSupportedBrowser = await isSupported();
//     if (isSupportedBrowser) {
//       return getMessaging(app);
//     }
//     console.log('Firebase not supported this browser');
//     return null;
//   } catch (err) {
//     console.log(err);
//     return null;
//   }
// })();
// console.log(messaging);
// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       resolve(payload);
//     });
//   });
// function requestPermission() {
//   console.log('Requesting permission...');
//   Notification.requestPermission().then((permission) => {
//     if (permission === 'granted') {
//       console.log('Notification permission granted.');
//     }
//     alert(permission);
//   });
// }
// requestPermission();
// export const getPushToken = () => {
//   return getToken(messaging, { vapidKey: process.env.REACT_APP_WEB_PUSH })
//     .then((currentToken) => {
//       if (currentToken) {
//         console.log('current token for client: ', currentToken);

//         // Track the token -> client mapping, by sending to backend server
//         // show on the UI that permission is secured
//       } else {
//         console.log('No registration token available. Request permission to generate one.');

//         // shows on the UI that permission is required
//       }
//     })
//     .catch((err) => {
//       console.log('An error occurred while retrieving token. ', err);
//       // catch error while creating client token
//     });
// };
