import { getAuth, signOut } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import { useUserContext } from '../../../hooks/useUser';
import { useAdminViewContext } from '../../../hooks/useAdminView';

export const SettingsComponent = () => {
  const { isAdminView, setIsAdminView } = useAdminViewContext();
  const auth = getAuth();
  const { user, resetUser } = useUserContext();

  const navigate = useNavigate();

  const handleAdminView = () => setIsAdminView((val: boolean) => !val);

  const handleButton = async () => {
    await signOut(auth);
    resetUser();
    navigate('/signin');
  };

  const adminOptions = [
    {
      name: 'Submission history',
      url: '/history',
    },
    {
      name: 'Leaderboard',
      url: '/leaderboard',
    },
    {
      name: 'activities',
      url: '/',
    },
    {
      name: 'prizes',
      url: '/prizes',
    },
    {
      name: 'logs',
      url: '/logs',
    },
  ];

  const renderAdminOptions = () => {
    if (user?.role === 'admin' && isAdminView) {
      return adminOptions.map((item, index) => (
        <Link key={index} to={item.url} className="btn w-full mb-3">
          {item.name}
        </Link>
      ));
    }
  };

  const renderAdminViewToggle = () => {
    if (user?.role === 'admin') {
      return (
        <div className="form-control mb-10">
          <label className="label cursor-pointer">
            <span className="label-text font-bold">Admin view</span>
            <input type="checkbox" className="toggle" checked={isAdminView} onChange={handleAdminView} />
          </label>
        </div>
      );
    }
  };

  return (
    <div>
      {renderAdminViewToggle()}
      {renderAdminOptions()}
      <button onClick={handleButton} className="btn w-full">
        Sign out
      </button>
    </div>
  );
};
