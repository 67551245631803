import constate from 'constate';
import { useState, useEffect } from 'react';

function getAdminView() {
  // getting stored isAdminView
  if (typeof window !== 'undefined') {
    const saved = localStorage.getItem('isAdminView');
    const initial = saved !== null ? JSON.parse(saved) : false;
    return initial;
  }
}

export const useAdminView = () => {
  const [isAdminView, setIsAdminView] = useState(() => {
    return getAdminView();
  });

  useEffect(() => {
    // storing input name
    localStorage.setItem('isAdminView', JSON.stringify(isAdminView));
  }, [isAdminView]);

  return { isAdminView, setIsAdminView };
};

export const [AdminViewProvider, useAdminViewContext] = constate(useAdminView);
